<template>
  <v-container>
    <v-row class="title-sticky">
      <v-col cols="12" lg="12">
        <TitleSection
          :title="`${recoverTitle[lang]}`"
          :subtitle="`${recoverSubTitle[lang]}`"
        ></TitleSection>
      </v-col>
    </v-row>
    <v-form ref="formRecoveryAccount" class="py-4" @submit.prevent="recovery">
      <v-container>
        <v-row :style="{ justifyContent: 'center' }">
          <!-- DOCUMENT -->
          <v-col cols="12" lg="4" md="4" sm="12">
            <v-row>
              <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
                <v-alert
                  v-if="error.visible"
                  border="left"
                  class="readable"
                  dense
                  :type="error.type"
                >
                  <strong>{{ error[lang] }}</strong>
                </v-alert>
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
                <v-text-field
                  :label="emailText[lang]"
                  :rounded="rounded"
                  :color="color"
                  class="readable"
                  :dark="dark"
                  :background-color="bgColor"
                  :rules="ruleEmail"
                  :disabled="disabled"
                  :hide-details="details"
                  :outlined="outlined"
                  :filled="filled"
                  :dense="dense"
                  :autocomplete="autocomplete"
                  v-model="payload.email"
                  :solo="solo"
                  :class="{ [roundedFieldclass]: roundedFieldclass }"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
                <v-btn
                  class="d-block w-100 readable"
                  :disabled="loading"
                  :loading="loading"
                  dark
                  depressed
                  type="submit"
                  color="tertiary"
                  :class="{ [roundedFieldclass]: roundedFieldclass }"
                >
                  {{ recoverLabel[lang] }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import baseConfig from "@/components/forms/baseConfig";
import { mapGetters, mapActions } from "vuex";
import { emailValidator } from "@/libs/validators.js";
import TitleSection from "@/components/titles/TitleSection.vue";

export default {
  components: {
    TitleSection,
  },
  data() {
    return {
      ...baseConfig,
      ruleEmail: [this.validateEmail],
      error: {
        type: "error",
        visible: false,
        en: "No results found",
        es: "No se encontraron resultados",
      },
      invalidEmail: {
        en: "Wrong email",
        es: "Correo incorrecto",
      },
      loading: false,
      payload: {
        email: "",
      },
      recoverTitle: {
        en: "RESTORE PASSWORD",
        es: "RESTAURAR CONTRASEÑA",
      },
      recoverSubTitle: {
        en:
          "Enter your email and we will send you the instructions to reset your password.",
        es:
          "Ingresa tu correo electrónico y te enviaremos las instrucciones para restaurar tu contraseña.",
      },
      emailText: {
        en: "E-mail",
        es: "Correo electrónico",
      },
      recoverLabel: {
        en: "Recover",
        es: "Recuperar",
      },
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
    }),
  },
  mounted() {
    this.$store.dispatch("changeAuthMenu", false);
  },
  methods: {
    ...mapActions({
      recoverIn: "recoverAccount",
    }),
    validateEmail(v) {
      return emailValidator(v) || this.invalidEmail[this.lang];
    },
    validateForm() {
      return this.$refs.formRecoveryAccount.validate();
    },
    async recovery() {
      if (!this.validateForm()) return;
      this.loading = true;
      const res = await this.recoverIn({
        vUsuario: this.payload.email,
      });
      this.loading = false;

      if (!res) {
        (this.error.type = "error"), (this.error.en = "No results found");
        this.error.es = "No se encontraron resultados";
      } else {
        if (res.DIAS != undefined) {
          if (res.DIAS == 0) {
            (this.error.type = "warning"),
              (this.error.en =
                "Your last request for password recovery was less than 24 hours ago, please try again later.");
            this.error.es =
              "Su última solicitud de recuperación de contraseña ha sido hace menos de 24 horas, intente mas tarde.";
          } else {
            (this.error.type = "success"),
              (this.error.en =
                "Password reset link sent to email " + this.payload.email);
            this.error.es =
              "Se envió el enlace de restauración de contraseña al correo " +
              this.payload.email;
          }
        } else {
          (this.error.type = "error"), (this.error.en = "No results found");
          this.error.es = "No se encontraron resultados";
        }
      }
      this.error.visible = true;
      setTimeout(() => {
        this.error.visible = false;
      }, 10000);
    },
  },
};
</script>

<style>
.v-alert.readable {
  margin-bottom: 2em;
}
</style>
